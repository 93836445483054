import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { GlobalContext } from '../context/globalContext';
import { dateDiff } from '../utils/geolocationHelper';
import Dialog from './Dialog';

function RangeConfirmDialog({ children }) {
  const [isInRange, setIsInRange] = useState(false);
  const [isValidLocation, setIsValidLocation] = useState(false);
  const { REACT_APP_MAX_DISTANCE } = process.env;
  const { locationInfo } = useContext(GlobalContext);

  useEffect(() => {
    if (REACT_APP_MAX_DISTANCE < locationInfo.distance) {
      setIsInRange(false);
    } else {
      setIsInRange(true);
    }
  }, [locationInfo.distance]);

  useEffect(() => {
    if (dateDiff(locationInfo.updatedAt) > 60) {
      setIsValidLocation(false);
    } else {
      setIsValidLocation(true);
    }
  }, [locationInfo.updatedAt]);

  if (isInRange) {
    return <Dialog>{children}</Dialog>;
  }
  return (
    <Dialog>
      <div className="mx-2 mb-5">
        {!isInRange && <h3>คุณอยู่นอกพื้นที่ลงเวลางาน</h3>}
        {!isValidLocation && <h3>ข้อมูลตำแหน่งไม่อัพเดท</h3>}
      </div>
      <Link className="w-full max-w-md my-2" to="/">
        <button type="button" className="w-full btn-yellow header">
          กลับไปหน้าหลัก
        </button>
      </Link>
    </Dialog>
  );
}

RangeConfirmDialog.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RangeConfirmDialog;
