import React from 'react';
import { useNavigate } from 'react-router-dom';

import Dialog from '../../components/Dialog';
import useInterval from '../../hooks/useInterval';

const AddResult = () => {
  const navigate = useNavigate();
  useInterval(() => {
    navigate('/');
  }, 5000);

  return (
    <Dialog>
      <h2 className="mb-4">
        <b>บันทึกข้อมูลสำเร็จ</b>
      </h2>
      <h3>นายทดสอบ นามสกุลยาวมากๆ</h3>
      <span>14 / 07 / 2564</span>
      <h1 className="text-5xl font-bold">08:45</h1>
      <div className="px-3 py-1 my-3 text-sm font-extrabold text-center text-white bg-red-500 rounded-full small">
        ลงเวลาเข้างาน | เช้า
      </div>
      <div className="relative w-full pt-4" style={{ maxWidth: '10rem' }}>
        <div className="flex h-1 mb-4 overflow-hidden text-xs bg-gray-200 rounded">
          <div
            style={{ animation: 'progressing 5s normal forwards' }}
            className="flex flex-col justify-center text-center text-white bg-green-500 shadow-none whitespace-nowrap"
          />
        </div>
      </div>
    </Dialog>
  );
};

AddResult.propTypes = {};

export default AddResult;
