import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { getBgClassName, getIcon } from '../../utils/varientUtils';

function ModalBody({ varient, title, head, message, footer }) {
  return (
    <>
      <div
        className={clsx(
          'flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full sm:mx-0 sm:h-10 sm:w-10',
          getBgClassName(varient)
        )}
      >
        {getIcon(varient)}
      </div>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <h3 className={clsx('text-lg font-bold leading-6')} id="modal-title">
          <p>{title}</p>
        </h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">{head}</p>
          <p className="text-sm text-gray-500">{message}</p>
          {footer}
        </div>
      </div>
    </>
  );
}

ModalBody.propTypes = {
  varient: PropTypes.oneOf(['success', 'warning', 'info', 'fail']),
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  head: PropTypes.string,
  footer: PropTypes.node,
};

ModalBody.defaultProps = {
  varient: 'info',
  head: '',
  footer: <></>,
};

export default ModalBody;
