import React from 'react';
import { Link } from 'react-router-dom';

import Favicon from '../../components/Favicon';

function NotFound() {
  return (
    <div className="container flex items-center justify-center mx-auto">
      <div className="max-w-md">
        <Favicon className="text-4xl" />
        <div className="mb-4 font-bold text-gray-400 text-8xl header">404</div>
        <p className="text-2xl font-bold leading-normal md:text-3xl header">
          ขออภัย ไม่พบหน้าที่คุณเรียก.{' '}
        </p>
        <p className="mb-8  header">หรือหน้าดังกล่าวอาจถูกลบแล้ว</p>

        <Link to="/">
          <button type="button" className="btn-green header">
            กลับไปหน้าหลัก
          </button>
        </Link>
      </div>
    </div>
  );
}

NotFound.propTypes = {};

export default NotFound;
