export function checkIsGeolocationSupport() {
  if ('geolocation' in navigator) {
    return true;
  }
  return false;
}

export function getCurrentPosition(callback, error, highAccuracy = false) {
  if (checkIsGeolocationSupport()) {
    navigator.geolocation.getCurrentPosition(callback, error, { enableHighAccuracy: highAccuracy });
  }
}

// calculating date difference in seconds
export function dateDiff(date1, date2 = Date.now()) {
  return parseInt((date2 - date1) / 1000, 10);
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

// calculating the shortest distance over the earth's surface – using the 'Haversine' formula.
export function getDistance(
  { latitude: lat1, longitude: lon1 },
  { latitude: lat2, longitude: lon2 }
) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d * 1000;
}

export function handleErrorLocation() {
  // console.warn(`ERROR(${err.code}): ${err.message}`);
}
