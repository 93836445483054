import PropTypes from 'prop-types';
import React from 'react';
import { HiX } from 'react-icons/hi';
import { Link } from 'react-router-dom';

function Dialog({ children }) {
  return (
    <>
      <Link to="/">
        <div className="x">
          <HiX size={40} opacity={0.6} />
        </div>
      </Link>

      <div className="container px-3 mx-auto bg-none">{children}</div>
    </>
  );
}

Dialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string])
    .isRequired,
};

export default Dialog;
