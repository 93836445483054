import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { IoFingerPrint } from 'react-icons/io5';

function Favicon({ className }) {
  return (
    <>
      <IoFingerPrint
        size="5rem"
        className={clsx(
          Favicon.defaultProps.className,
          className.replace(Favicon.defaultProps.className, '')
        )}
      />
    </>
  );
}

Favicon.defaultProps = {
  className: 'mx-auto self-center mb-3 text-green-400',
};

Favicon.propTypes = {
  className: PropTypes.string,
};
export default Favicon;
