import clsx from 'clsx';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { HiX } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';

import Favicon from '../../components/Favicon';
import LoadSpinner from '../../components/LoadSpinner';
import Modal from '../../components/Modal/Modal';
import ModalBody from '../../components/Modal/ModalBody';
import { GlobalContext } from '../../context/globalContext';
import { getBgClassName, getButtonClassName } from '../../utils/varientUtils';

function Register() {
  const { REACT_APP_API_REGISTER_DEVICE_URL } = process.env;
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [resultmessage, setResultMessage] = useState('');
  const {
    deviceInfo: { deviceId },
  } = useContext(GlobalContext);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleSuccessResponse = () => {
    navigate('/');
  };

  const handleRegisterResponse = async (data) => {
    setResultMessage(data.message ? data.message : '');
    setIsSuccess(data.isSuccess ? data.isSuccess : false);
    setIsLoading(false);
    setShowModal(true);
    if (data.isSuccess) {
      setTimeout(handleSuccessResponse, 1800);
    }
  };

  const handleRegisterRequest = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    fetch(REACT_APP_API_REGISTER_DEVICE_URL, {
      body: JSON.stringify({
        deviceId,
        cid: event.target.cid.value,
      }),
      method: 'POST',
    })
      .then((response) => response.json())
      .then((data) => {
        handleRegisterResponse(data);
      })
      .catch((data) => handleRegisterResponse(data));
  };

  return (
    <div className="container mx-auto">
      <Link to="/">
        <div className="x">
          <HiX size={40} opacity={0.6} />
        </div>
      </Link>
      <Modal
        show={showModal}
        setShow={setShowModal}
        className={clsx(
          isSuccess ? getBgClassName('success') : getBgClassName('fail'),
          'header text-xl'
        )}
        disableCloseButton
        clickButtonStyle={isSuccess ? getButtonClassName('success') : getButtonClassName('fail')}
        clickButtonText="ปิด"
        handleClick={() => setShowModal(false)}
      >
        <ModalBody
          varient={isSuccess ? 'success' : 'fail'}
          message={resultmessage}
          title={isSuccess ? 'ลงทะเบียนสำเร็จ' : 'ลงทะเบียนไม่สำเร็จ'}
        />
      </Modal>
      <div>
        <Favicon />
        <div className="mx-2 mb-5">
          <h2>ลงทะเบียนอุปกรณ์</h2>
        </div>

        <form onSubmit={handleRegisterRequest} className="px-4" autoComplete="off">
          <input name="deviceId" type="hidden" value={deviceId} readOnly />
          <label className="block" htmlFor="cid">
            <span className="text-gray header">กรอกเลขประจำตัวประชาชน</span>
            <input
              ref={inputRef}
              name="cid"
              type="number"
              className="max-w-sm py-3 my-3 text-sm text-xl font-bold text-center text-gray-700 placeholder-gray-400 bg-white border-0 rounded focus:outline-none ease-linear transition-all duration-150"
            />
          </label>
          {!isLoading && (
            <button
              type="submit"
              className="w-full max-w-sm btn-green header disabled:opacity-50 disabled:bg-transparent"
              disabled={isLoading}
            >
              ลงทะเบียน
            </button>
          )}
          <div className="flex items-center justify-center">
            <LoadSpinner isLoading={isLoading} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
