import './styles/style.css';
import './styles/button.scss';
import './styles/input.scss';
import './styles/font.scss';
import './styles/transition.css';

import { lazy, Suspense, useContext, useEffect } from 'react';
import { Route } from 'react-router-dom';
import SlideRoutes from 'react-slide-routes';

import LoadSpinner from './components/LoadSpinner';
import { GlobalContext } from './context/globalContext';
import useGeoLocation from './hooks/useGeolocation';
import useLocalStorage from './hooks/useLocalStorage';
import Register from './pages/devices/Register';
import NotFound from './pages/errors/NotFound';
import AddResult from './pages/log/Result';
import TimeSelect from './pages/log/TimeSelect';
import TypeSelect from './pages/log/TypeSelect';
import { getDiviceId, validateDeviceId } from './utils/deviceHandler';
import { getDistance, handleErrorLocation } from './utils/geolocationHelper';

const Home = lazy(() => import('./pages/Home'));

function App() {
  const { setDeviceInfo, setLocationInfo, targetLocation } = useContext(GlobalContext);
  const [deviceId, setDeviceId] = useLocalStorage('deviceId', { deviceId: '', updatedAt: '' });

  const handleGeolocation = ({ coords: { latitude, longitude, accuracy } }) => {
    const distance = getDistance({ latitude, longitude }, targetLocation);
    setLocationInfo({ latitude, longitude, accuracy, distance, updatedAt: Date.now() });
  };

  useGeoLocation(handleGeolocation, handleErrorLocation);
  // init device ID
  useEffect(() => {
    // device id retrieve
    (async () => {
      const isValidDeviceId = validateDeviceId(deviceId?.deviceId);
      if (!isValidDeviceId) {
        const id = getDiviceId();
        setDeviceId({ deviceId: id, updatedAt: Date.now() });
      }
    })();
  }, []);

  // auto update device info to global context when deviceId changed
  useEffect(() => {
    if (deviceId) {
      setDeviceInfo(deviceId);
    }
  }, [deviceId]);

  return (
    <div className="text-gray-700 app" style={{ backgroundColor: 'rgb(237, 237, 239)' }}>
      <Suspense
        fallback={
          <div className="container mx-auto">
            <LoadSpinner isLoading />
          </div>
        }
      >
        <SlideRoutes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/log" element={<TypeSelect />} />
          <Route path="/log/:type/result" element={<AddResult />} />
          <Route path="/log/:type" element={<TimeSelect />} />
          <Route element={NotFound} />
        </SlideRoutes>
      </Suspense>
    </div>
  );
}

export default App;
