import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

// Device Context
export const GlobalContext = createContext({
  deviceInfo: {},
  setDeviceInfo: () => {},
  resetDeviceInfo: () => {},
  locationInfo: {},
  setLocationInfo: () => {},
  targetLocation: {},
  setTargetLocation: () => {},
  resetLocationInfo: () => {},
  isRegistered: false,
  setIsRegistered: () => {},
  formData: {},
  setFormData: () => {},
  resetFormData: () => {},
});

export const GlobalContextProvider = ({ children }) => {
  // initail State
  const defaultFormData = { deviceId: '', type: '', period: '', dateTime: '' };
  const defaultDeviceInfo = { deviceId: '', updatedAt: '' };
  const defaultLocationInfo = {
    latitude: 0,
    longitude: 0,
    accuracy: 0,
    distance: 0,
    updatedAt: '',
  };
  const defaultTargetLocation = {
    latitude: 6.503373204020809,
    longitude: 101.659855959457,
    accuracy: 0,
  };

  const [deviceInfo, setDeviceInfo] = useState(defaultDeviceInfo);
  const [locationInfo, setLocationInfo] = useState(defaultLocationInfo);
  const [targetLocation, setTargetLocation] = useState(defaultTargetLocation);
  const [isRegistered, setIsRegistered] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);

  // resetting handlers
  const resetFormData = () => {
    setFormData(defaultFormData);
  };

  const resetDeviceInfo = () => {
    setDeviceInfo(defaultDeviceInfo);
  };

  const resetLocationInfo = () => {
    setLocationInfo(defaultLocationInfo);
  };

  // value composition
  const store = {
    deviceInfo,
    setDeviceInfo,
    resetDeviceInfo,
    locationInfo,
    setLocationInfo,
    targetLocation,
    setTargetLocation,
    resetLocationInfo,
    isRegistered,
    setIsRegistered,
    formData,
    setFormData,
    resetFormData,
  };

  return <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>;
};

GlobalContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
};

GlobalContextProvider.defaultProps = {
  children: <></>,
};
