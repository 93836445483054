import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

function Modal({
  children,
  show,
  setShow,
  handleClick,
  clickButtonText,
  clickButtonStyle,
  disableCloseButton,
  autoHide,
}) {
  // auto hide in seconds
  useEffect(() => {
    if (autoHide && show) {
      setTimeout(() => {
        setShow(false);
      }, autoHide * 1000);
    }
  }, [autoHide, show]);

  return (
    show && (
      <div
        className={clsx('fixed inset-0 z-10 overflow-y-auto')}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          {/* Background overlay, show/hide based on modal state. */}
          <Transition
            show={show}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          />

          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          {/*  Modal panel, show/hide based on modal state. */}
          <Transition
            show={show}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="inline-block overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">{children}</div>
            </div>

            <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
              {clickButtonText && (
                <button
                  type="button"
                  onClick={handleClick}
                  className={clsx(
                    'inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm',
                    clickButtonStyle || 'bg-red-600 hover:bg-red-700'
                  )}
                >
                  {clickButtonText}
                </button>
              )}
              {!disableCloseButton && (
                <button
                  type="button"
                  onClick={() => setShow(!show)}
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 header rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  ปิด
                </button>
              )}
            </div>
          </Transition>
        </div>
      </div>
    )
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  handleClick: PropTypes.func,
  clickButtonText: PropTypes.string,
  clickButtonStyle: PropTypes.string,
  disableCloseButton: PropTypes.bool,
  autoHide: PropTypes.number,
};

Modal.defaultProps = {
  children: <></>,
  handleClick: () => {},
  clickButtonText: '',
  clickButtonStyle: '',
  disableCloseButton: false,
  autoHide: 0,
};

export default Modal;
