import { FaCheck, FaExclamation } from 'react-icons/fa';

const VARIENTS = {
  success: {
    Icon: <FaCheck className="text-green-500" />,
    textClassName: 'text-green-500',
    btnClassName: 'btn-green',
    bgClassName: 'bg-green-100',
  },
  warning: {
    Icon: <FaExclamation className="text-yellow-500" />,
    textClassName: 'text-yellow-500',
    btnClassName: 'btn-yellow',
    bgClassName: 'bg-yellow-100',
  },
  info: {
    Icon: <FaExclamation className="text-blue-500" />,
    textClassName: 'text-blue-500',
    btnClassName: 'btn-blue',
    bgClassName: 'bg-blue-100',
  },
  fail: {
    Icon: <FaExclamation className="text-red-500" />,
    textClassName: 'text-red-500',
    btnClassName: 'btn-red',
    bgClassName: 'bg-red-100',
  },
};

export const getBgClassName = (varient) =>
  VARIENTS[varient]?.bgClassName || VARIENTS.info.bgClassName;
export const getButtonClassName = (varient) =>
  VARIENTS[varient]?.btnClassName || VARIENTS.info.btnClassName;
export const getTextClassName = (varient) =>
  VARIENTS[varient]?.textClassName || VARIENTS.info.textClassName;
export const getIcon = (varient) => VARIENTS[varient]?.Icon || VARIENTS.info.Icon;
