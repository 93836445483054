import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Modal from '../../components/Modal/Modal';
import ModalBody from '../../components/Modal/ModalBody';
import RangeConfirmDialog from '../../components/RangeConfirmDialog';
import { GlobalContext } from '../../context/globalContext';
import { postLogData } from '../../utils/dataHandler';
import { getBgClassName, getButtonClassName } from '../../utils/varientUtils';

function TimeSelect() {
  const { type } = useParams();
  const navigate = useNavigate();
  const {
    deviceInfo: { deviceId },
  } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState({ message: '', success: false });
  const ResponseModal = () => (
    <Modal
      show={showResponseModal}
      setShow={setShowResponseModal}
      className={clsx(
        getBgClassName(responseMessage.success ? 'success' : 'fail'),
        'header text-xl'
      )}
      clickButtonStyle={getButtonClassName(responseMessage.success ? 'success' : 'fail')}
      clickButtonText={responseMessage.success ? 'ปิด' : 'ลองใหม่'}
      handleClick={() => {
        navigate('/');
      }}
      disableCloseButton
    >
      <ModalBody
        varient={responseMessage.success ? 'success' : 'fail'}
        title={responseMessage.success ? 'บันทึกข้อมูลสำเร็จ' : 'บันทึกข้อมูลล้มเหลว'}
        message={responseMessage.message}
      />
    </Modal>
  );

  const handlePostLogData = (event) => {
    event.preventDefault();
    if (isLoading) {
      return;
    }
    const { shift } = event.currentTarget.dataset;
    const data = {
      deviceId,
      type,
      shift,
      verifyMode: 'mobile',
    };
    setIsLoading(true);
    postLogData(data)
      .then(({ message }) => {
        setResponseMessage({ message, success: true });
        setIsLoading(false);
        setShowResponseModal(true);
      })
      .catch(({ message }) => {
        setResponseMessage({ message, success: false });
        setIsLoading(false);
        setShowResponseModal(true);
      });
  };

  return (
    <RangeConfirmDialog>
      <ResponseModal />
      <div className="mx-2 mb-5">
        <h3>เลือกช่วงเวลา</h3>
      </div>
      <div className="flex w-full max-w-md mb-3 justify-content-ceter">
        <div className="flex-1 mx-2">
          <button
            data-shift="morning"
            type="button"
            className="w-full btn-blue header"
            onClick={handlePostLogData}
          >
            <b>เช้า</b>
          </button>
        </div>
        <div className="flex-1 mx-2">
          <button
            data-shift="evening"
            type="button"
            className="w-full btn-blue header"
            onClick={handlePostLogData}
          >
            <b>บ่าย</b>
          </button>
        </div>

        <div className="flex-1 mx-2">
          <button
            data-shift="morning"
            type="button"
            className="w-full btn-blue header"
            onClick={handlePostLogData}
          >
            <b>ดึก</b>
          </button>
        </div>
      </div>
      <div className="flex w-full max-w-md mb-3 justify-content-ceter">
        <div className="flex-1 mx-2">
          <button
            data-shift="day"
            type="button"
            className="w-full btn-pink header flex-grow-1"
            onClick={handlePostLogData}
          >
            <b>เวรเดย์</b>
          </button>
        </div>

        <div className="flex-1 mx-2">
          <button
            data-shift="night"
            type="button"
            className="w-full btn-pink header flex-grow-1"
            onClick={handlePostLogData}
          >
            <b>เวรไนท์</b>
          </button>
        </div>
      </div>
    </RangeConfirmDialog>
  );
}

TimeSelect.propTypes = {};

TimeSelect.defaultProps = {};

export default TimeSelect;
