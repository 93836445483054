import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function useGeoLocation(success, error, options) {
  const [watcherId, setWatcherId] = useState(null);

  useEffect(() => {
    // initiate geolocation watcher
    setWatcherId(navigator.geolocation.watchPosition(success, error, options));
    // cleanup geolocation watcher
    return () => {
      navigator.geolocation.clearWatch(watcherId);
    };
  }, []);
}

useGeoLocation.propTypes = {
  success: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  options: PropTypes.shape({
    enableHighAccuracy: PropTypes.bool,
    timeout: PropTypes.number,
    maximumAge: PropTypes.number,
  }),
};

useGeoLocation.defaultProps = {
  options: {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  },
};

export default useGeoLocation;
