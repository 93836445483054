const {
  REACT_APP_API_CHECK_DEVICE_URL,
  REACT_APP_API_GET_USERDATA,
  REACT_APP_API_ADD_LOG_URL,
  REACT_APP_API_FETCH_LOG_URL,
} = process.env;

export const RESPONSES = {
  EMPTY_DEVICE_ID: 'ไม่พบอัตลักษณ์อุปกรณ์ กรุณาลองใหม่อีกครั้ง',
  INCOMPLETE_DATA: 'ข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
  UNAUTHORIZED: 'อุปกรณ์ของท่านยังไม่ลงทะเบียน กรุณาลงทะเบียนก่อนใช้งาน',
  ERROR: 'เกิดข้อผิดพลาดในการเชื่อมต่อเซิฟเวอร์ กรุณาลองใหม่อีกครั้ง',
};
/**
 * Get device status
 *
 * Description: Get device status by sending POST request to the server.
 *
 * @param {string}  deviceId  Device ID string
 *
 *
 * @return {Promise} Promise object represents the response message and register status.
 */
export const getDeviceStatus = (deviceId) =>
  new Promise((resolve, reject) => {
    if (!deviceId) {
      reject(new Error(RESPONSES.EMPTY_DEVICE_ID));
    } else {
      fetch(REACT_APP_API_CHECK_DEVICE_URL, {
        body: JSON.stringify({
          deviceId,
        }),
        method: 'POST',
      })
        .then((response) => response.json())
        .then(({ message, isSuccess: registered }) => {
          if (registered) {
            resolve({ message, registered });
          }
          reject(new Error(RESPONSES.UNAUTHORIZED));
        })
        .catch(() => {
          reject(new Error(RESPONSES.ERROR));
        });
    }
  });

/**
 * Get user's data
 *
 * Description: Get user's data by sending POST request contains deviceId to the server.
 *
 * @param {string}  deviceId  Device ID string
 *
 *
 * @return {Promise} Promise object represents the user's data
 */
export const getUserData = (deviceId) =>
  new Promise((resolve, reject) => {
    if (!deviceId) {
      reject(new Error(RESPONSES.EMPTY_DEVICE_ID));
    } else {
      fetch(REACT_APP_API_GET_USERDATA, {
        body: JSON.stringify({
          deviceId,
        }),
        method: 'POST',
      })
        .then((response) => response.json())
        .then(({ name, mainDepartment, mainDepartmentId, avatar }) =>
          resolve({ name, mainDepartment, mainDepartmentId, avatar })
        )
        .catch(() => reject(new Error(RESPONSES.ERROR)));
    }
  });

/**
 * POST log data
 *
 * Description: Get user's data by sending POST request contains deviceId to the server.
 *
 * @param {string}  deviceId  Device ID string
 *
 *
 * @return {Promise} Promise object represents the user's data
 */
export const postLogData = ({ deviceId, type, shift, verifyMode }) =>
  new Promise((resolve, reject) => {
    if (!deviceId) {
      reject(new Error(RESPONSES.EMPTY_DEVICE_ID));
    } else if (!type || !shift || !verifyMode) {
      reject(new Error(RESPONSES.INCOMPLETE_DATA));
    } else {
      fetch(REACT_APP_API_ADD_LOG_URL, {
        body: JSON.stringify({
          deviceId,
          type: String(type).toUpperCase(),
          shift,
          verifyMode,
        }),
        method: 'POST',
      })
        .then((response) => response.json())
        .then(({ message }) => resolve({ message }))
        .catch(() => reject(new Error(RESPONSES.ERROR)));
    }
  });

/**
 * fetching log data
 *
 * Description: Get user's data by sending POST request contains deviceId to the server.
 *
 * @param {string}  deviceId  Device ID string
 *
 *
 * @return {Promise} Promise object represents the user's logs data
 */
export const getLogs = ({ deviceId, count }) =>
  new Promise((resolve, reject) => {
    if (!deviceId) {
      reject(new Error(RESPONSES.EMPTY_DEVICE_ID));
    } else {
      fetch(REACT_APP_API_FETCH_LOG_URL, {
        body: JSON.stringify({
          deviceId,
          n: count || 4,
        }),
        method: 'POST',
      })
        .then((response) => response.json())
        .then(({ message, logs }) => resolve({ message, logs }))
        .catch(() => {
          reject(new Error(RESPONSES.ERROR));
        });
    }
  });
