import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react'

function useInterval(callback, delay) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified, or it's running on server-side
    if (delay === null || typeof window === 'undefined') {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)
    
    // eslint-disable-next-line consistent-return
    return () => clearInterval(id);

  }, [delay])
}

useInterval.propTypes = {
  callback: PropTypes.func.isRequired,
  delay: PropTypes.number,
}

useInterval.defaultProps = {
  delay: null,
}

export default useInterval
