import React from 'react';
import { Link } from 'react-router-dom';

import RangeConfirmDialog from '../../components/RangeConfirmDialog';

function TypeSelect() {
  return (
    <RangeConfirmDialog>
      <div className="mx-2 mb-5">
        <h3>เลือกประเภทการลงเวลา</h3>
      </div>
      <Link className="w-full max-w-md my-2" to="/log/in">
        <button type="button" className="w-full btn-blue header">
          ลงเวลาเข้างาน
        </button>
      </Link>
      <Link className="w-full max-w-md my-2" to="/log/out">
        <button type="button" className="w-full btn-pink header">
          ลงเวลาออก
        </button>
      </Link>
    </RangeConfirmDialog>
  );
}

TypeSelect.propTypes = {};

export default TypeSelect;
