import PropTypes from 'prop-types';
import React from 'react';
import { CgSpinner } from 'react-icons/cg';

function LoadSpinner({ isLoading, size }) {
  return (
    isLoading && <CgSpinner className="mx-auto my-3 text-green-400 animate-spin" size={size} />
  );
}

LoadSpinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  size: PropTypes.string,
};

LoadSpinner.defaultProps = {
  size: '3rem',
};

export default LoadSpinner;
